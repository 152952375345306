import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

@inject('navigationStore')
@observer
class PageLayer extends Component {

  render () {
    const store = this.props.navigationStore;
    const toggleNav = store.toggleNav;
    const isActive = store.navIsActive;
    const setCurrentItem = store.setCurrentItem;
    const currentItem = store.currentItem;
    const onClick = item => e => {
      setCurrentItem(item)
      toggleNav();
    };

    return (
      <div className="dd-pagelayer">
        <div className="dd-navbar-toggler-container">
          { Object.keys(store.menuItems).map((key, i) => {
            return (
              <button key={key} className="dd-navbar-toggler d-block" onClick={onClick(store.menuItems[key])} type="button">
                <span className="dd-navbar-toggler-icon"></span>
                  {store.menuItems[key].button}
              </button>
            );
          })}
        </div>
        
        <div className={isActive ? 'dd-pagelayer__container dd-opened' : 'dd-pagelayer__container'}>
          <button className="navbar-toggler d-block" onClick={toggleNav} type="button">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={'dd-pagelayer__content ' + (isActive ? 'dd-opened' : '')}>
            { (isActive && currentItem) &&
              <div className="row">
                <div className="col-12">
                  <iframe title={currentItem.title} src={currentItem.src} frameBorder="0"></iframe>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default PageLayer;
