import React, { Component } from 'react';
import { Switch, matchPath} from 'react-router-dom';
import { ROUTES as routes , RouteWithSubRoutes } from './routes/routes';
import Header from './components/header/Header/Header';
import Intro from './components/intro/Intro/Intro';
import PageLayer from './containers/PageLayer/PageLayer';
import ShareButtons from './components/nav/ShareButtons/ShareButtons';


class App extends Component {

  render() {
    const location = this.props.location;
    let match = null;
    for (let i=0; i< routes.length; i++) {
      match = matchPath(location.pathname, routes[i]);
      if (match) {
        break;
      }
    }

    return (
      <div>
        <div className="wrap">
          <Header/>
          <div className="content">
            <main className="mink-main container-fluid">
              <Switch key={match.path}>
                  {routes.map((route, i) =>(
                    <RouteWithSubRoutes  key={i} {...route} />
                  ))}
                </Switch>
            </main>
          </div>
          <Intro/>
          <PageLayer/>
          <ShareButtons/>
        </div>
      </div>
    );
  }
}

export default App;
