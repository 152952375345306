import React, { Component } from 'react';

export default class ShareButtons extends Component {


  render() {
    
    //const url = window.location.href;
    const url = 'http://mdr.de/static/wissen/deutschland-doppel';
    const encodedUrl = encodeURIComponent(url);
    const title = document.title;
    const encodedTitle = encodeURIComponent(title);
    const img = encodeURIComponent('https://cdn.mdr.de/wissen/deutschland-doppel/deutschland-doppel-104_v-variantBig16x9_wm-true_zc-ecbbafc6.jpg?version=9550');
    const fb = "//www.facebook.com/sharer.php?u=" + encodedUrl + '&t=' + encodedTitle ;
    const twitter = "//twitter.com/intent/tweet?text=" + encodedTitle + '&url=' + encodedUrl;
    const pinterest = "//www.pinterest.de/pin/create/link/?url=" + encodedUrl + '&media=' + img + '&description=' + encodedTitle;
    const wa = "whatsapp://send?text=" + encodedTitle + ' - ' + encodedUrl;
    const mail = "mailto:?subject=Link-Tipp: " + encodedTitle + '&body=Link-Tipp: '+ encodedTitle + encodeURIComponent("\r\r") + encodedUrl;


    return (
      <div className="dd-socialshare">
        <ul className="ui-social-links">
          <li className="ui-social-btn facebook"><a
              href={fb}
              target="_blank"
              rel="noopener noreferrer"
              title="Diese Seite auf Facebook teilen"><span className="d-none">Diese Seite auf Facebook teilen</span> </a></li>
          <li className="ui-social-btn twitter"><a
              href={twitter}
              target="_blank"
              rel="noopener noreferrer"
              title="Diese Seite auf Twitter teilen"><span className="d-none">Diese Seite auf Twitter teilen</span></a></li>
          <li className="ui-social-btn pinterest"><a
              href={pinterest}
              target="_blank"
              rel="noopener noreferrer"
              title="Diese Seite auf Pinterest teilen"><span className="d-none">Diese Seite auf Pinterest teilen</span></a></li>
          <li className="ui-social-btn whatsapp"><a 
            href={wa}
            title="Diese Seite per whatsapp teilen"><span className="d-none">Diese Seite per whatsapp teilen</span></a></li>
          <li className="ui-social-btn mail"><a 
            href={mail} title="Diese Seite per E-mail teilen"><span className="d-none">Diese Seite per E-mail teilen</span></a></li>
        </ul>
      </div>
    );
  }
}

/*
$('[name="description"]').attr("content");
  t || (t = "");
  var i = r + encodeURIComponent("\r\r" + t);
  return document.location.href = "mailto:?subject=" + e + "&body=Link-Tipp: " + i + "%0D%0A%0D%0A" + n.shareUrl, !1
}
 */