import { action, observable } from 'mobx';

export default class NavigationStore {

  @observable navIsActive = false;
  @observable currentItem = null;

  menuItems = {
    topten : {
      button: 'Top Ten',
      title: 'Top Ten | MDR.DE',
      src: 'https://www.mdr.de/wissen/deutschland-doppel/top-zehn-mitteldeutschland-100.html'
    },
    imprint : {
      button: 'Impressum',
      title: 'Impressum | MDR.DE',
      src: 'https://www.mdr.de/wissen/deutschland-doppel/dd-impressum-100.html'
    },
    transparency : {
      button: 'Über das Projekt',
      title: 'Über das Projekt | MDR.DE',
      src: 'https://www.mdr.de/wissen/deutschland-doppel/dd-ueber-das-projekt-100.html'
    }
  };

  @action
  setCurrentItem = (item) => {
    this.currentItem = item;
  }

  @action
  toggleNav = (e) => {
    this.navIsActive = !this.navIsActive;
    this.navIsActive ? document.body.classList.add('overflow') : document.body.classList.remove('overflow');

  };

}
