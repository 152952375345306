
import { action, observable, reaction, computed } from 'mobx';
import { track } from '../../helpers/tracking';

export default class SearchStore {

  districts = [];
  afterGeoJsonLoadedReaction = null;
    // Autosuggest is a controlled component.
  // This means that you need to provide an input value
  // and an onChange handler that updates this value (see below).
  // Suggestions also need to be provided to the Autosuggest,
  // and they are initially empty because the Autosuggest is closed.
  @observable
  value =  '';

  /**
   * current selected district
   */
  currentDistrict = null;
  suggestions = [];

  constructor(dataStore) {
    this.dataStore = dataStore;
    /**
     * after twins geoJson is loaded prepare auto suggestions
     */
    this.afterGeoJsonLoadedReaction = reaction(
      () => this.dataStore.isFetched.get('geoJson'),
      (isFetched) => {
        isFetched && this.buildSuggestions();
      }
    );
  }

  buildSuggestions() {
    if (this.dataStore.data.geoJson.features) {
      this.districts = [];
      const features = this.dataStore.data.geoJson.features;
      for (let i=0; i < features.length; i++) {
        const item = {
          name: features[i].properties.name_2,
          type: features[i].properties.type_2,
          id: features[i].properties.id_2,
        }
        this.districts.push(item);
      }
    }
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  @action
  onSuggestionsFetchRequested = ({ value }) => {
    this.suggestions =  this.getSuggestions(value);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  @action
  onSuggestionsClearRequested = () => {
    this.suggestions = [];
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const regex = RegExp(inputValue,'i');
    const inputLength = inputValue.length;
    return inputLength < 3 ? [] : this.districts.filter(item =>
      //item.name.toLowerCase().slice(0, inputLength) === inputValue
      regex.test(item.name)
    );
  };

    /*
     * When suggestion is clicked, Autosuggest needs to populate the input
     * based on the clicked suggestion. Teach Autosuggest how to calculate the
     * input value for every given suggestion.
     *
     * @param {Object} suggestion
     * @returns {String}
     */
    getSuggestionValue = (suggestion) => {
      this.currentDistrict = suggestion;
      // maybe we should submit the form here
      this.maybeSetActiveDistrict();
      return suggestion.name;
    };

    /**
     * check if current value is in list, and set current district to this value
     * 1. check if curent value fits to stored this.currentDistrict take this district
     * 2. if not, try to find district by value in districts array
     * 
     */
    @action
    validateInput = () => {
      if (this.value.length > 2) {
        if ( 
          this.currentDistrict &&
          this.currentDistrict.name.toLowerCase() === this.value.toLowerCase()
        ) {
          return;
        }
        this.currentDistrict = this.districts.find(item => {
          return item.name.toLowerCase() === this.value.toLowerCase();
        });
      } else {
        this.currentDistrict = null
      }
    }

    @action
    onChange = (event, { newValue }) => {
      this.value = newValue;
      this.validateInput();
    };

    @computed
    get isValidSearchInput(){
      return this.currentDistrict && this.currentDistrict.id;
    };

    @action
    maybeSetActiveDistrict(){
      const trackObj = {
        event: 'searchForm.submited'
      }
      if (this.isValidSearchInput) {
        //set active district
        this.dataStore.setPendingSearchDistrict(this.currentDistrict);
        trackObj.district = this.currentDistrict.name;
        track(trackObj);
      } else {
        const suggestions = this.getSuggestions(this.value)
        if (suggestions.length) {
          this.currentDistrict = suggestions[0]
          this.value = suggestions[0].name;
          //set active district
          this.dataStore.setPendingSearchDistrict(this.currentDistrict);
          trackObj.district = this.currentDistrict.name;
          track(trackObj);
        }
      }
    }
}