import React from 'react';
import { Route, matchPath } from 'react-router-dom';
import Home from '../containers/Home/Home';
import {default as stores } from '../stores/index';


const ROUTES = [
  {
    path: "*",
    component: Home,
  },
];


// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
const RouteWithSubRoutes = (route) => {
  if (route.children) {
    return (
      <Route
        path={route.path}
        exact={route.exact}
        children={({ ...props })=> {
          // pass the sub-routes down to keep nesting
          return <route.children {...props} routes={route.routes} path={route.path} siblingPaths={route.siblingPaths}/>
        }}
      />);
  } else {
    return (
      <Route
        location={route.location}
        path={route.path}
        exact={route.exact}
        render={ props => {
            // pass the sub-routes down to keep nesting
            return <route.component {...props} routes={route.routes} path={route.path} siblingPaths={route.siblingPaths} store={route.store} apiParams={route.apiParams}/>
          }}

      />);
    }
};

const getCurrentRoute = () => {
  const store = stores.routerStore;
  const routes = ROUTES;
  let match = null;
  for (let i=0; i< routes.length; i++) {
    match = matchPath(store.location.pathname, routes[i]);
    if (match) {
      return routes[i];
    }
  }
  return routes[routes.length - 1];
};

/**
 * 
 * @param {integer} id term id
 * @param {string} taxonomy slug
 *
 * @returns {object|boolean} route object or false
 */
const getRouteByPath = ( path ) => {
  const routes = ROUTES;
  return path !== undefined && routes.find((route) => matchPath(path, route));
};

export { ROUTES, RouteWithSubRoutes, getCurrentRoute, getRouteByPath };
