import { action, observable, computed } from 'mobx';

export default class ContentStore {

  @observable
  isOpen = false;

  @observable
  collapsed = true;

  constructor(dataStore) {
    this.dataStore = dataStore;
  }

  @action
  toggleCollapse = () => {
    this.dataStore.showContentBox = !this.dataStore.showContentBox;
  }
  @action
  setCollapse = (value) => {
    this.dataStore.showContentBox = value;
  }

  @computed
  get showContentBox(){
    return this.dataStore.showContentBox && this.dataStore.canShowContentBox;
  }

  @action
  toggleIsCollapsed(){
    this.collapsed = !this.collapsed;
  }
  /**
   * @param {true|false} value 
   */
  @action
  setIsCollapsed(value){
    this.collapsed = value;
  }
}