import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Autosuggest from 'react-autosuggest';

@inject('searchStore')
@observer
class Search extends Component {

  onSubmit = (event) => {
    event.preventDefault();
    this.props.searchStore.maybeSetActiveDistrict();
  }

  render(){
    const store = this.props.searchStore;

    // Use your imagination to render suggestions.
    const renderSuggestion = suggestion => (
      <div>
        <span className="react-autosuggest__suggestion--name">
          {suggestion.name},&nbsp;
        </span>
        <span className="react-autosuggest__suggestion--type">
          {suggestion.type}
        </span>
      </div>
    );

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Finden Sie hier Ihren Landkreis',
      value: store.value,
      onChange: store.onChange,
    };

    // maybe onSuggestionSelected submit form

    return (
      <div className="search-form">
        <form className="search-form__form" onSubmit={this.onSubmit}>
          <Autosuggest
            suggestions={store.suggestions}
            onSuggestionsFetchRequested={store.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={store.onSuggestionsClearRequested}
            getSuggestionValue={store.getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            alwaysRenderSuggestions={false}
          />
          <button type="submit" onClick={this.onSubmit} className="btn btn-dd -submit">Suchen</button>
        </form>
      </div>
    );
  }

}

export default Search;