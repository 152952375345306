/**
  Helper functions for communication with REST API
 */
import React from 'react';
import Helmet from 'react-helmet';
import Parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { Link } from 'react-router-dom';

const getHomeUrl = () => {
  return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port: '/');
};

const getSiteName = () => {
  let siteName = 'MDR.DE ';
  return siteName;
}

const createMarkup = (text) => {
  return {__html: text};
}

const renderTitle = (text, withSiteName) => {
  withSiteName = withSiteName !== false;
  if (text && text.rendered) {
    text = text.rendered;
  }
  if (withSiteName) {
    text = text + ' | ' + getSiteName();
  }
  if (text) {
    return  <Helmet><title>{Parser(text)}</title></Helmet>;
  } else {
    return null;
  }
}

const replaceCallback = (domNode) => {
  if (
    domNode.name === 'a' &&
    domNode.attribs &&
    domNode.attribs.href
  ) {
    const href = domNode.attribs.href;
    const isRouterLink = !/^http|^\/\/|^\/content|^#|^mailto/.test(href);
    if (isRouterLink) {
      return <Link to={href}>{domToReact(domNode.children)}</Link>
    }
  }
};

const parserOptions = {
  replace: replaceCallback
}

const renderText = (text) => {
  if (text) {
    if (text.rendered) {
      text = text.rendered;
    }
    return  Parser(text, parserOptions);
  } else {
    return null;
  }
}

const getQueryStringParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {}
};

const parseUrlComponents = url => {
  const parser = document.createElement("a");
    parser.href = url;
    return {
      protocol: parser.protocol,
      hostname: parser.hostname,
      port: parser.port,
      pathname: parser.pathname,
      search: parser.search,
      hash: parser.hash,
      host: parser.host,
    }
}

const germanize = (x) => {
  return x.toLocaleString('de-DE');
}

export {
  getHomeUrl,
  getSiteName,
  createMarkup,
  renderTitle,
  renderText,
  parserOptions,
  getQueryStringParams,
  parseUrlComponents,
  germanize,
};
