import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Slider from "react-slick";
import {Modal, ModalBody} from 'reactstrap';

@inject('trackingStore')
@observer
class Intro extends Component {

  slider = React.createRef();

  state = {
    isOpen: true
  };

  onQuitButtonClicked = (e) => {
    this.toggle();
    this.props.trackingStore.track({
      event: 'startApp',
    });
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: true,
    dots: true,
    beforeChange: (oldIndex, newIndex) => {
      const store = this.props.trackingStore;
      store.track({
        event: 'introsliderchanged',
        oldIndex: 'from.' + oldIndex,
        newIndex: 'to.' + newIndex,
      });
    }
  };

  render(){
    return (
      <Modal
        isOpen={this.state.isOpen}
        toggle={this.toggle}
        className="dd-intro"
        backdrop="static"
        backdropClassName="dd-intro"
      >
        <ModalBody>
          <div className="">
            <div className="">
              <h3>Willkommen beim Deutschland-Doppel</h3>
            </div>
            <Slider className="dd-intro__slider" {...this.settings} ref={this.slider}>
              <div className="">
                <p>Hier können Sie herausfinden, welche Kreise oder kreisfreien Städte sich in Deutschland ähneln.</p>
              </div>
              <div className="">
                <p>In der Suchleiste am oberen Kartenrand können Sie den Kreis oder die kreisfreie Stadt eintippen, für die Sie den Landkreiszwilling suchen.</p>
              </div>
            </Slider>
            <div className="col-12 justify-content-center">
              <button onClick={this.onQuitButtonClicked} className="w-100 btn btn-dd -next" type="button">Los geht's</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

}

export default Intro;