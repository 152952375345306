import React, { Component } from 'react';
import Search from '../Search/Search';
import ContentBox from '../ContentBox/ContentBox';

class Header extends Component {
  
  render() {
    return(
      <header className="mink-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <a className="-link" href="https://www.mdr.de/wissen">zurück</a>
            </div>
            <div className="col-auto d-flex -headline-lg">
              <h1 className="d-none d-lg-block -headline">Finden Sie den Zwilling zu Ihrem Landkreis</h1>
            </div>
            <div className="col d-flex justify-content-end">
            <a href="https://www.mdr.de/wissen" className="mink-header__logo">
              mdr wissen
            </a>
            </div>
          </div>
          <div className="row">
            <div className="d-lg-none col-12 d-flex justify-content-center -headline-sm">
              <h1 className="-headline">Finden Sie den Zwilling zu Ihrem Landkreis</h1>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <Search/>
            </div>
            <div className="col-12 d-flex justify-content-center">
              <ContentBox/>
            </div>
            
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
