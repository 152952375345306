import React, { Component, Suspense, lazy} from 'react';
import { inject, observer } from 'mobx-react';
import { renderTitle } from '../../helpers/api';
import ScrollToTop from '../../components/nav/ScrollToTop/ScrollToTop';
import LoadingAnimation from '../../components/elements/LoadingAnimation/LoadingAnimation';
//import HomeMap from '../../components/maps/HomeMap/HomeMap';

const HomeMap = lazy(() => import('../../components/maps/HomeMap/HomeMap'));


@inject('routerStore')
@observer
class Home extends Component {

  slug = '';
  store = {};

  renderHead() {
    return renderTitle('Deutschland Doppel');
  }

  componentDidMount() {
    
    document.body.classList.add('home');
  }
  componentWillUnmount() {
    document.body.classList.remove('home');
  }

  render() {
    const scrollToTop = !this.props.location.hash && (!this.props.location.state || !this.props.location.state.scrollTo);
    return (
      <div className="mink-page">
        <div className="mink-page__content">
          {scrollToTop && <ScrollToTop onHistoryPush={true}/>}
          {this.renderHead()}
            <div className="row">
              <div className="col-12">
                <Suspense fallback={<LoadingAnimation/>}>
                  <HomeMap/>
                </Suspense>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Home;
