import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Collapse } from 'reactstrap';
import ContentBoxSlider from '../ContentBoxSlider/ContentBoxSlider';



@inject('contentStore', 'dataStore', 'trackingStore')
@observer
class ContentBox extends Component {

  containerRef = React.createRef();
  twinContainerRef = React.createRef();

  componentDidMount(){
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  /**
   * Close Contentbox on click outside
   */
  handleClickOutside = (event) => {
    if (
      !this.props.contentStore.collapsed &&
      this.props.contentStore.showContentBox &&
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target)
    ) {
      this.props.contentStore.setCollapse(false);
      this.props.trackingStore.track({event: 'detailview.closed'});
    }
  }

  render(){
    const cStore = this.props.contentStore;
    const dStore = this.props.dataStore;
    const index = dStore.currentTwinDataIndex;
    const show = cStore.showContentBox;

    return (
      <div className={'dd-content-box -wrapper'} onClick={this.onClick} ref={this.containerRef}>
        <Collapse
          isOpen={show}
          onEntered={() => {cStore.setIsCollapsed(false)}}
          onExited={() => {cStore.setIsCollapsed(true)}}
        >
          {dStore.currentTwinId && dStore.currentTwinData ?
            (
              <div className="-content -twins position-relative" ref={this.twinContainerRef}>
                <ContentBoxSlider key={dStore.currentTwinData[0].distId} index={index}/>
              </div>
            ) : (
              null
            )
          }
        </Collapse>
      </div>
    )
  }

}

export default ContentBox