import React, { Component } from 'react';

class LoadingAnimation extends Component {
  render() {
    const style = this.props.style ? this.props.style : null;
    return (
      <div className="row" style={style}>
        <div className="col-12 kn-la">
          <div className="kn-la__dual-ring"></div>
        </div>
      </div>
    );
  }
}

export default LoadingAnimation;