import stores from '../stores/index';

const track = (args) => {
  stores.trackingStore.track(args);
}

const escape = (str) => {
  return stores.trackingStore.escape(str);
}

export { track, escape };
