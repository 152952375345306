import './helpers/polyfill'
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import { Provider } from 'mobx-react';
import { Router, Route } from 'react-router-dom';
import {default as stores, history } from './stores/index';

ReactDOM.render(
  <Provider {...stores}  history={history}>
    <Router history={history}>
      <Route path="*" component={App}/>
    </Router>
  </Provider>,
  document.getElementById('app_root')
);
/*registerServiceWorker({
  onUpdate: async registration => {
    if ( process.env && 'production' === process.env.NODE_ENV ) {
      // https://github.com/facebook/create-react-app/issues/5316
      // We want to run this code only if we detect a new service worker is
      // waiting to be activated.
      // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
      if (registration && registration.waiting) {
        await registration.unregister();
        // Makes Workbox call skipWaiting()
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        // Once the service worker is unregistered, we can reload the page to let
        // the browser download a fresh copy of our app (invalidating the cache)
        window.location.reload();
      }
    }
  },
});*/
unregister();