import React, { Component } from 'react';
import Slider from "react-slick";
import { inject, observer } from 'mobx-react';
import { germanize } from '../../../helpers/api';

@inject('contentStore', 'dataStore', 'trackingStore')
@observer
class ContentBoxSlider extends Component {

  slider = React.createRef();
  duration = 500;
  beforeChangeTimeOut = null;

  // https://github.com/akiran/react-slick/issues/1262
  settings = {
    infinite: true,
    speed: this.duration,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: this.props.index,
    onInit: () => {
      const data = this.props.dataStore.currentTwinData[this.props.index];
      this.props.trackingStore.track({event: 'detailview.opened.district.' + data.distName + '.twin.' + data.twinName});
    },
    beforeChange: (oldIndex, newIndex) => {
      if (-1 !== newIndex && this.props.dataStore.currentTwinData.length) {
        this.beforeChangeTimeOut = setTimeout(() => {
          this.props.dataStore.setCurrentTwinDataIndex(newIndex);
        }, this.duration);
        const data = this.props.dataStore.currentTwinData[newIndex];
        this.props.trackingStore.track({event: 'detailview.nexttwin.district.' + data.distName + '.twin.' + data.twinName});
      }
    },
    swipeToSlide: false,
    adaptiveHeight: true,
    arrows: false,
    dots: false,
    accessibility: false,
    draggable: false,
    swipe: false,
    touchMove: false,
    focusOnSelect: false,
    fade: true,
  };

  onClickNext = (e) => {
    if (this.slider.current) {
      this.slider.current.slickNext();
    }
  }

  getHeadline = (data) => {
    return (
      <div className="col-12 header">
        <h3 className="-headline ">
          <span className="-name">{data.distName}</span> und <span className="-name">{data.twinName}</span> ähneln sich in den Kategorien: 
        </h3>
      </div>
    );
  }

  render() {
    const dStore = this.props.dataStore;

    return (
      <Slider className="dd-content-box__slider" {...this.settings} ref={this.slider}>
        {
          dStore.currentTwinData.map((twin, twinIndex) => {
            return (
              <div key={twin.twinId} className={'dd-content-box__slider-item'}>
                <div className="row">
                {this.getHeadline(twin)}
                  {
                    twin.categories.map((item, i) => {
                      const data = twin;
                      const hasAvergae = Boolean(item.average.value);
                      return (
                        <div key={data.distId + '_' + data.twinId + '_' + i} className="-category col-12">
                          <h4 className="-headline">{item.value}</h4>
                          <div className="-value-dist"><span className="-name">{data.distName}:</span> {germanize(data.distValues[i].value)}</div>
                          <div className="-value-twin"><span className="-name">{data.twinName}:</span> {germanize(data.twinValues[i].value)}</div>
                          {hasAvergae && <div className="-value-average"><span className="-name">{item.average.label}</span> {item.avarageDesc}: {germanize(item.average.value)}</div>}
                        </div>
                      );
                    })
                  }
                  <div className="col-12 justify-content-center">
                    { 1 < dStore.currentTwinData.length &&
                      <button onClick={this.onClickNext} className="w-100 btn btn-dd -next" type="button">nächster Doppelgänger für diese Auswahl</button>
                    }
                  </div>
                </div>
              </div>
            );
          })
        }
      </Slider>
    );
  }

}

export default ContentBoxSlider;
