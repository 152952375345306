export default class TrackingStore {
  /**
   * holds the routerStore
   */
  routerStore = {};
  /**
   * // To stop listening, call the function returned from listen().
   * unlisten();
   */
  unlisten = null;

  constructor(routerStore) {
    this.routerStore = routerStore;

    this.unlisten = routerStore.history.listen((location, action) => {
      // location is an object like window.location
      this.track();
    });
    //this.track();
  }

  formatAtiParam(value) {
    return encodeURIComponent(value).replace(/-/g, '%2D').replace(/_/g, '%5F');
  }

  escape = (str) => {
    return str.replace(/\s|-/g, '_');
  }

  track = (args) => {
    const p = this.routerStore.location.pathname.split('/').join('.');
    let path = p.slice(0, p.length - 1);
    /*{
      event: 'matchFound',
      selection: LandkreisA.id,
      match: LandkreisB.id,
      category: Attribut.id
    }*/

    if (typeof window.measurement !== 'undefined' && typeof window.measurement.atInternet !== 'undefined') {
      //const appname = "deutschlanddoppel";
      //const page_title = "Deutschland-Doppel";
      const siteName = 'deutschlanddoppel';
      const clientName = 'mdrde.';

      if(args) {
        const argValues = Object.values(args);
        //arguments.unshift(appname);
        path = this.escape(argValues.join("."));
      }
      path = '.' + path;
      const customVars = {

        3: this.formatAtiParam('[' + clientName + siteName + path + ']'),
        5: this.formatAtiParam('[' + siteName  + path + ']'),
        6: this.formatAtiParam('[' + siteName + ']'),
        11: this.formatAtiParam('[' + siteName + ']'),
        13: this.formatAtiParam('[' + clientName + 'tool.' + siteName + path + ']'),
        14: this.formatAtiParam('[' + clientName + 'tool.' + siteName + ']')

      }
      //console.log(customVars, path);
      window.measurement.atInternet.callBaseTag({
        site: 596352, //PRD: 596352 oder DEV: 596353
        page: {
          "​​chapter1": "wissen",
          "chapter2": "",
          "chapter3": "",
          "level2": "1", // mdr.de
          "name": "deutschland-doppel"
        },
        customVars: {
          site: customVars
        }
      });
    }
  };
}
