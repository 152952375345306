import { action, observable, reaction } from 'mobx';
import neatCsv from 'neat-csv';
import {shuffle} from '../helpers/array';

export default class DataStore {

  dataSrc = {
    "geoJson": {path: './data/landkreise_output_p3.json', type: 'json'},
    "twinsJson": {path: './data/dict_twins_purged.json', type: 'json'},
    "attributes": {path: './data/attributes.csv', type: 'csv'}
  }

  csvOptions = {
    separator : ';',
    headers: false,
  };

  @observable
  isFetched = new Map();
  isFetching = {};
  data = {};
  @observable
  pendingTwinDistrict = null;
  /**
   * {L.geoJSON|null}
   */
  @observable
  currentActiveDistrict = null;

  /**
   * {L.geoJSON|null} a district choosen by search, pending for its twin
   * @see HomeMap::pendingSearchDistrictReaction 
   */
  @observable
  pendingSearchDistrict = null;
  /**
   * {L.geoJSON|null}
   */
  @observable
  currentActiveTwin = null;
  @observable
  currentTwins = null;
  @observable
  currentTwinData = [];

  @observable
  currentTwinId = null;
  @observable
  currentTwinDataIndex = 0;
  @observable
  showContentBox = false;
  @observable
  canShowContentBox = false;
  @observable
  snakeAnimComplete = false;

  /**
   * holds the current ref to GeoJson Layer
   */
  geoJsonRef = {};

  /**
   * @param {object} params 
   */
  @action
  fetch( params ) {
    // check for object param
    let {path, slug, type } = params;
    path = !path && this.dataSrc[slug] ? this.dataSrc[slug].path : path;
    type = !type && this.dataSrc[slug] ? this.dataSrc[slug].type : type;

    this.isFetching[path] = true;

    fetch(path)
      .then((response) => {
        if (response.ok) {
          if ('json' === type) {
            return response.json()
          } else {
            return response.text()
          }
        } else {
          Promise.reject(response.status);
        }
      })
      .then(data => {
        this.isFetching[path] = false;
        if(data) {
          const key = slug ? slug : path;
          if ('csv' === type) {
            neatCsv(data, this.csvOptions).then(
              data => {
                this.data[key] = data;
                this.isFetched.set(key, true);
              }
            );
          } else {
            this.data[key] = data;
            this.isFetched.set(key, true);
          }
          
        }
      }).catch(error => console.error(error));;
  }

  fetchIfNeeded = (slug) => {
    slug = slug ? slug : 'geoJson';
    if ( !this.isFetching[slug] && !this.isFetched.get(slug)) {
      this.fetch({slug: slug});
    }
  }

  /**
   * after twins json is loaded call find twins in case there is a pending district waiting for a twin
   */
  afterTwinsJsonLoadedReaction = reaction(
    () => this.isFetched.get('twinsJson'),
    (isFetched) => {
      isFetched && this.findTwin();
    }
  );

  /**
   * after twins json is loaded call find twins in case there is a pending district waiting for a twin
   */
  afterAttributesLoadedReaction = reaction(
    () => this.isFetched.get('attributes'),
    (isFetched) => {
      isFetched && this.setTwinsDataAttributes();
    }
  );

  @action
  findTwin = () => {
    if (this.isFetched.get('twinsJson') && this.pendingTwinDistrict) {
      const twins = Object.entries(this.data.twinsJson);
      // district id is id_2
      const id = this.pendingTwinDistrict.feature.properties.id_2;
      // get twins from for this d from json Object
      const matches = twins.find( item => parseInt(item[0]) === id);
      if (matches) {
        // on this point we need the attributes
        this.fetchIfNeeded('attributes');
        // get twin districts
        this.currentTwins = matches;
        this.currentActiveDistrict = this.pendingTwinDistrict;
        this.pendingTwinDistrict = null;
        this.resetPendingSearchDistrict();
        // prepare districts with rich data
        this.setTwinsDataAttributes();
      }
    }
  }

  @action
  setTwinsDataAttributes(){
    if (this.isFetched.get('attributes') && this.currentTwins) {
      // district data - data[0] containes category headers
      const data = this.data.attributes;
      // console.log(this.currentTwins);
      const attributes = [];

      const dist = this.currentTwins[0];
      const distData = data.find( value => dist === value[2]);
      const twins = this.currentTwins[1];
      const average = data.find(value => value[0] === '99');
      const averageDesc = data.find(value => value[0] === '100');

      for(let id in twins) {
        const twin = data.find( value => id === value[2]);
        const attr = {
          distId : dist,
          distName : distData[3],
          distType : distData[4],
          twinId : id,
          twinName : twin[3],
          twinType : twin[4],
          categories: [],
          distValues:[],
          twinValues:[],
        };
        // console.log(distData);
        // cat ids are in  a nested array in purged json
        for (let j=0; j < twins[id][0].length; j++) {
          // console.log(twin);
          // find category data, include average values
          attr.categories.push({
            id: twins[id][0][j],
            value: data[0][twins[id][0][j]],
            average: {
              label: average[1],
              value: this.round(average[twins[id][0][j]])
            },
            averageDesc: averageDesc[twins[id][0][j]]
          });
          attr.distValues.push({
            id: twins[id][0][j],
            value: this.round(distData[twins[id][0][j]])
          });
          attr.twinValues.push({
            id: twins[id][0][j],
            value: this.round(twin[twins[id][0][j]])
          });
        }
        attributes.push(attr);
      }

      this.currentTwinData = shuffle(attributes);
      if (attributes.length) {
        this.currentTwinId = attributes[0].twinId;
        this.currentTwinDataIndex = 0;
        this.currentActiveTwin = this.getLayerByDistrictId(this.currentTwinId);
        this.showContentBox = true;
        this.canShowContentBox = false;
      } else {
        this.showContentBox = false;
        this.canShowContentBox = false;
        this.currentTwinId = null;
        this.currentActiveTwin = null;
      }
    }
  }

  /**
   * @param {Object}
   */
  setPendingSearchDistrict = (currentDistrictFromSearchStore) => {
    const id = currentDistrictFromSearchStore.id;
    const layer = this.getLayerByDistrictId(id);
    if (layer && this.currentActiveDistrict !== layer) {
      this.pendingSearchDistrict = layer;
    }

    // if the searched district is the active district maybe reopen the contentbox
    if (!this.showContentBox && layer && this.currentActiveDistrict === layer) {
      this.showContentBox = true;
    }
  }

  resetPendingSearchDistrict = () => {
    this.pendingSearchDistrict = null;
  }

  @action
  setCurrentTwinDataIndex = (newIndex) => {
    this.currentTwinDataIndex = newIndex;
    this.currentTwinId = this.currentTwinData[newIndex].twinId;
    //reset style form active twin
    if(this.geoJsonRef && this.geoJsonRef.current){
      this.geoJsonRef.current.leafletElement.resetStyle(this.currentActiveTwin);
    }
    this.currentActiveTwin = this.getLayerByDistrictId(this.currentTwinId);
  }
  /**
   * helper function, round to 2 digits 
   * @param {number} value 
   */
  round( value ) {
    return Math.round(value * 100) / 100;
  }

  /**
   * @param {String} id District Id
   * @returns {null|GeoJson}
   */
  getLayerByDistrictId(id){
    let layer = null;
    // id = parseInt(id, 10);
    if (this.geoJsonRef && this.geoJsonRef.current) {
      const layers = this.geoJsonRef.current.leafletElement.getLayers();
      layer = layers.find(layer => parseInt(id, 10) === layer.feature.properties.id_2);
    }
    return layer;
  }
}