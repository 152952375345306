import {RouterStore} from 'mobx-react-router';
import WindowStore from './UI/WindowStore';
import SearchStore from './UI/SearchStore';
import ContentStore from './UI/ContentStore';
import TrackingStore from './UI/TrackingStore';
import NavigationStore from './UI/NavigationStore';
import DataStore from './DataStore';

import { syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';


const routerStore = new RouterStore();

const windowStore = new WindowStore();
const dataStore = new DataStore();

const browserHistory = createBrowserHistory();
export const history = syncHistoryWithStore(browserHistory, routerStore);

export default {
  routerStore: routerStore,
  windowStore: windowStore,
  dataStore: dataStore,
  searchStore: new SearchStore(dataStore),
  contentStore: new ContentStore(dataStore),
  trackingStore: new TrackingStore(routerStore),
  navigationStore: new NavigationStore(routerStore),
  // place for other stores...
};
